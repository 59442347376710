import { Flex } from '@chakra-ui/react'
import { Header } from 'src/components/common'
import { Hero, DecouvrezNosOperations, Footer, Faq, LesAgriculteursPartenaires, LaDemarche } from '@components/home'

export default function Home() {
  return (
    <>
      <Flex direction="column" w="full" height="full" alignItems="center" overflowX="unset">
        <Header />
        <Hero />
        <DecouvrezNosOperations />
        <LesAgriculteursPartenaires />
        <LaDemarche />
        <Faq />
        <Footer />
      </Flex>
    </>
  )
}
